.hosting-container {
  width: 100%;
  height: auto;
  background-color: rgb(250, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0px;

  h1 {
    margin: 10px 0;
  }

  > div {
    width: 90%;
    padding-top: 10px;

    > p {
      color: var(--gray-gray-600, #718096);
      font-feature-settings: "salt" on;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }

    > div {
      display: flex;
      flex-direction: column;

      > h2 {
        color: var(--gray-gray-800, #2d3748);
        /* Headings/Headings-h4 */
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        margin: 5px 0;
      }

      > p {
        color: var(--gray-gray-600, #718096);
        font-feature-settings: "salt" on;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }

  .hosting-button {
    display: flex;
    justify-content: center;

    > a {
      text-decoration: none;
      padding: 15px 30px;
      border-radius: 5px;
      background: linear-gradient(rgb(250, 138, 138), rgb(249, 88, 89));
      /* Buttton-Shadow */
      box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
      color: white;
      font-size: 18px;
      font-weight: 500;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
