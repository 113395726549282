.header {
  width: 100%;
  background-color: white;
  padding: 10px 0px;
  margin: 0px;
  height: 60px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: sticky;
  top: 0%;
  z-index: 100;

  .menu {
    display: none;
    > button {
      display: none;
      border: none;
      background-color: white;
      cursor: pointer;
      > img {
        width: 30px;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;

    > a {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      align-items: center;
      cursor: pointer;

      > img {
        height: 40px;
      }

      > h1 {
        color: rgb(249, 88, 89);
        font-weight: 700;
        font-size: 24px;
        margin: 12px;
      }
    }
  }

  .middle {
    display: flex;
    justify-content: flex-end;

    > ul {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      gap: 40px;

      > li > a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
        color: rgb(128, 128, 128);

        &:hover {
          color: rgb(249, 88, 89);
        }
      }
    }
  }

  .right {
    > a {
      background: linear-gradient(rgb(250, 138, 138), rgb(249, 88, 89));
      border-radius: 5px;
      color: white;
      font-size: 18px;
      font-weight: 500;
      border: none;
      padding: 8px 18px;
      text-decoration: none;
    }
  }
}
