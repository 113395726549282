@media screen and (max-width: 1250px) {
  .web-development {
    > div {
      width: 48%;
    }
  }
}

@media screen and (max-width: 950px) {
  .header {
    flex-direction: row;
    justify-content: space-around;

    .menu {
      display: inline;
      position: absolute;
      left: 20px;
      > button {
        display: inline;
      }
    }
    .left {
      position: absolute;
      left: 80px;
    }

    .display-view {
      display: block;
    }

    .hide-view {
      display: none;
    }

    .middle {
      position: fixed;
      justify-content: flex-start;
      left: 0;
      top: 80px;
      background-color: #fff;
      width: 100%;
      height: auto;
      padding: 20px 0;
      border-bottom: 1px solid gray;

      > ul {
        list-style-type: none;
        display: flex;
        padding-left: 25px;
        flex-direction: column;
        gap: 40px;

        > li > a {
          text-decoration: none;
          font-size: 20px;
          font-weight: 300;
          color: rgb(128, 128, 128);

          &:hover {
            color: rgb(249, 88, 89);
          }
        }
      }
    }

    .right {
      margin: 0;
      position: absolute;
      right: 20px;
    }
  }

  .web-development {
    flex-direction: column;
    height: auto;

    > div {
      width: 100%;
      > div {
        > h1 {
          padding-top: 1.5rem;
        }
      }
    }
  }
  .dev-image {
    display: none;
  }

  .web-design {
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px auto;

    > div {
      width: 100%;
      flex-direction: column;
      padding: 0;

      > div {
        width: 100%;
        padding-bottom: 20px;
        > p {
          margin: 0px 20px;
        }
        > h3 {
          margin-left: 20px;
        }
      }
    }
  }

  .services-container {
    > div {
      flex-direction: column;

      .tile-container {
        height: auto;
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    .left {
      > a {
        > img {
          height: 30px;
        }
        > h1 {
          font-size: 22px;
        }
      }
      > h1 {
        font-size: 10px;
        color: black;
      }
    }
  }
  .web-development {
    > div {
      width: 100%;
      > div {
        padding-left: 30px;
      }
    }
  }

  .web-design {
    > div {
      border-radius: 6px 6px 30px 30px;
    }
  }

  .dev-image {
    display: none;
  }

  .services-container {
    padding-left: 0;
    > div {
      width: 98%;
      .tile-container {
        > div {
          padding: 20px;
        }
      }
    }
  }

  .footer-container {
    > div {
      flex-direction: column;
      gap: 30px;
    }
  }
}
