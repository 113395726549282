.web-development {
  width: 100%;
  height: 90vh;
  max-height: 800px;
  background-color: white;
  z-index: 0;
  // margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 40%;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 40px;
      padding-top: 0px;

      > h1 {
        color: var(--gray-gray-900, #1a202c);
        margin: 10px 0;
        font-size: 45px;
        font-style: normal;
        font-weight: 600;
        line-height: 71px;

        > span {
          background: linear-gradient(
            180deg,
            #de4396 0%,
            rgba(13, 28, 159, 0) 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
        }
      }

      > p {
        color: var(--gray-gray-700, #4a5568);
        margin: 5px 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }

      > a {
        padding: 15px 30px;
        text-decoration: none;
        border-radius: 5px;
        background: linear-gradient(rgb(250, 138, 138), rgb(249, 88, 89));
        box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
        color: white;
        font-size: 20px;
        font-weight: 500;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.web-design {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  margin-bottom: 40px;

  > div {
    border-radius: 10px 30px 30px 10px;
    border: 1px solid var(--shade-primary-lite-bg, #e7daed);
    background: #f1f2ff;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > img {
        width: 100%;
        height: 100%;
      }
      > h3 {
        color: var(--gray-gray-800, #2d3748);
        margin-left: 50px;
        /* Headings/Headings-h3 */
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 136.023%;
      }

      > p {
        color: var(--gray-gray-700, #4a5568);
        font-feature-settings: "salt" on;
        /* Paragraphs/default */
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 162.023%;
        margin: 0 50px;
      }
    }
  }
}
